
// forms
.formulari {
    margin-bottom: 4rem;
    .form-group {
        margin-bottom: 1rem;
    }
}


// ctf7
div.wpcf7 .ajax-loader {
    display: none !important;
}
.wpcf7-list-item-label {
    padding-left: 23px;
}

.wpcf7-list-item {
    margin: 0 !important;
}

.option-left-normal {
    overflow: hidden;

    input[type=radio],
    input[type=checkbox] {
        display: none;
    }

    input[type=radio]+label,
    input[type=checkbox]+label {
        padding: 0 0 0 22px;
        margin: 0 auto;
        display: inline-block;
        cursor: pointer;
        position: relative;
    }

    input[type=checkbox]+label:before, input[type=checkbox]+.wpcf7-list-item-label:before {
        content: '';
        border-radius: 0;
    }

    input[type=checkbox]:checked+label:before,  input[type=checkbox]:checked+.wpcf7-list-item-label:before {
        //font-family: 'ElegantIcons';
        content: "✓";
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
        line-height: 1;

    }

    input[type=radio]+label:before {
        content: '';
        border-radius: 50%;
        cursor: pointer;
    }

    input[type=radio]+label:before,
    input[type=checkbox]+label:before {
        //font-size: 12px;
        //line-height: 14px;
        //color: #000;
        //text-align: center;
    }

    label {
        display: inline-block;
        //float: left;
        cursor: pointer;
        position: relative;
        font-size: 14px;


        &:before {
            float: left;
            content: '';
            display: flex;
            align-self: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            margin: -8px 0 0 0;
            background-color: $white;
            border: 1px solid $gray-500;
            position: absolute;
            top: 50%;
            left: 0;
            //font-size: 10px;

        }
    }
    .wpcf7-list-item-label {
        display: inline-block;
        //float: left;
        cursor: pointer;
        position: relative;
        font-size: 14px;


        &:before {
            float: left;
            content: '';
            display: flex;
            align-self: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            margin: -8px 0 0 0;
            background-color: $white;
            border: 1px solid $gray-500;
            position: absolute;
            top: 50%;
            left: 0;
            //font-size: 10px;
        }
    }
}

