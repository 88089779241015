$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

body {
    background-color: $primary;
    overflow-x: hidden;
}

img {
    max-width: 100%;
}

.container {
    padding: 0 15px;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    font-family: $font-title;
    margin-top: 3rem;
    margin-bottom: 2rem;
    line-height: 1;
}

.btn-primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
    min-width: 150px;
    &:hover, &:focus {
        color: $primary;
        background-color: #fff;
        border-color: $primary;
    }
}




main.content {
    background-color: #fff;
    min-height: calc(100vh - 240px);
}