// Cookies
.botons {
    .cli-plugin-button {
        margin: 0 20px;
        min-width: 150px;
    }
}


.menu-footer {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        font-family: $font-title;
        li {
            width: 50%;
            @media (min-width: $sm) {
                width: 33.333%;
            }
            @media (min-width: $lg) {
                width: 16.666%;
            }
            a {
                display: flex;
                width: 100%;
                min-height: 60px;
                border-left: 1px solid $primary;
                padding: 10px 20px;
                text-decoration: none;
                font-size: 1.5rem;
                line-height: 1;
                transition: all .4s ease-in-out; 
                &:hover, &:focus {
                    color: #000;
                    border-left: 1px solid #000;
                }
                @media (min-width: $lg) {
                    min-height: 120px;
                }

            }
        }
    }
}




.footer {
    background-color: $primary;
    color: #fff;
    padding: 30px 0;
    a {
        color: #fff;
        text-decoration: none;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .col-contact {

    }
    .col-social {
        margin-left: auto;

    }
    .col-legal {

    }
    .col-rights {
        margin-left: auto;
    }
}