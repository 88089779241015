.home {
    .header .logo {
        opacity: 0;
    }
}


.header {
    background-color: #fff;
    .logo {
        margin: 15px 0;
        display: inline-block;

        img {
            height: 30px;
            width: auto;
        }
    }

    .contact {
        margin: auto auto auto 0;
        text-decoration: none;
        display: none;

        @media (min-width: $sm) {
            display: inline-block;
        }

        &:hover,
        &:focus {
            color: $black;
        }
    }

    .lang {
        list-style: none;
        display: flex;
        margin: auto 0 auto auto;
        padding: 0;
        li {
            border-right: 1px solid $primary;

            &:last-child {
                border: 0;

                a {
                    padding-right: 0;
                }
            }

            &.active {
                a {
                    color: $black;
                }
            }

            a {
                padding: 0 15px;
                display: block;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $black;
                }
            }
        }
    }

    .burguer {
        margin: auto 0 auto 15px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        .a-open {
            display: none;
        }
        .a-close {
            display: inline-block;
        }
        &.collapsed {
            .a-open {
                display: inline-block;
            }
            .a-close {
                display: none;
            }
        }
    }

    .full-menu {
        width: 100%;
        padding: 0 20px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
        }
        .menu-principal {
            font-family: $font-title;
            font-size: 1.4rem;
            li {
                a {
                    display: block;
                    padding: 10px 0;
                    color: #000;
                    border-bottom: 1px solid $primary;
                    text-decoration: none;
                    transform: translatex(100%);
                    transition: transform 0.4s ease-in-out, opacity 0.4s linear;
                    &:hover, &:focus {
                        color: $primary
                    }
                    
                }
                @for $i from 1 through 7 {
                    &:nth-child(#{$i}) {

                        a {
                            transition-delay: $i * 0.05s;
                        }
                    }
                }
            }
        }
    }

    .site-menu {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 0;
        height: 100%;
        background-color: #eee;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 100px 0;
        z-index: 700;
        transition: all linear .2s;
        transform: translate(300px, 0);
        overflow: hidden;
        //display: none;
        &.show {
            right: 0;
            width: 300px;
            transform: translate(0, 0);
            transition: all .2s ease-in-out; 
            .full-menu {
                .menu-principal {
                    li {
                        a {
                            transform: translatex(0);
                        }
                    }
                }
            }
            
        }
    }

    .row-header {
        position: relative;
        z-index: 800;
    }
}
