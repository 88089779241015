
.page-title {
    margin-top: 4rem;
    margin-bottom: 2rem;
    color: $primary;
}
.featured-text {
    margin-bottom: 2rem;
    color: $primary;
}

.mb-grid {
    margin-bottom: 4rem;
}

.vc_single_image-wrapper  {
    img {
        width: 100%;
    }
}

.flex-content {
    margin: auto -5px;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .flex-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        height: 100%;
        min-height: calc(100vh - 420px);
        align-items: stretch;
        align-content: stretch;
    }

    .flex-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
    }

    .item-content {
        align-items: flex-start;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        //padding: 20px;
        font-size: 2rem;
        background-color: $primary;
        color: #fff;
        font-family: $font-title;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        .bg-cover {
            position: absolute;
            top: 100%;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.4);
            //mix-blend-mode: multiply;
            transition: all .2s ease-in-out; 
        }
        .bg-text {
            position: relative;
            line-height: 1;
        }
        a {
            color: #fff;
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: block;
            padding: 20px;
            position: relative;
            &:hover, &:focus {
                .bg-cover {
                    top: 0;
                    background-color: rgba($primary,1);
                }
            }
        }
    }

    

    .subitem1 {
        width: 100%;
        display: flex;
        
        padding: 5px;
        @media (min-width: $sm) {
            height: 66.666%;
        }
    }

    .subitem2 {
        width: 100%;
        display: flex;
        
        padding: 5px;
        @media (min-width: $sm) {
            height: 33.333%;
        }
    }


    .flex-item.item1 {
        width: 100%;
        padding: 5px;
        .item-content {
            background-color: #fff;
            color: $primary;
        }
        
        @media (min-width: $sm) {
            width: 66.666%;
        }
        img {
            max-width: 320px;
            margin-bottom: 2rem;
        }

    }

    .flex-item.item2 {
        width: 100%;
        padding: 5px;
        @media (min-width: $sm) {
            width: 33.333%;
        }
    }

    .flex-item.item3 {
        width: 100%;
        padding: 5px;
        @media (min-width: $sm) {
            width: 66.666%;
        }
    }

    .flex-item.item4 {
        width: 100%;
        @media (min-width: $sm) {
            width: 33.333%;
        }
    }

}



.partners {
    display: flex;
    flex-wrap: wrap;
    .partner {
        width: 25%;
        display: block;
        margin: 15px 0;
        padding: 0 15px;
        text-align: center;
        img {
            max-width: 100%;
            max-height: 80px;
            margin: 0 auto;
        }
    }
}

.img-text {
    margin: 2rem 0;
    padding: 15px 0 0 0;
    width: 100%;
    border-top: 3px solid $primary;
}

.featured-block {
    border-bottom: 3px solid $primary;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
}

.map-item {
    width: 100%;
    height: 300px;
    margin-bottom: 4rem;
    @include media-breakpoint-up(lg) {
        height: 400px;
    }
}
